<template>
  <div class="item-options text-center" >
    <!-- <div  v-if="product.categoryName=='Packages'" class="ribbon ribbon-top-right">
      <span>{{product.categoryName}} </span>
    </div> -->
    <div class="d-flex justify-content-between mb-2">
      <p class="subtotalproduct" v-if="!isReloadingProductsInCart">{{product.displayrates.currency}} {{ subTotalProductoQty(product) }}</p>
      <b-skeleton width="65%" class="ml-1 mr-1" v-else></b-skeleton>

      <b-button variant="danger"  @click="deleteProductInCart(product)" > <feather-icon icon="TrashIcon" /> </b-button>
    </div>
    <div class="d-flex justify-content-center" v-if="product.belongsToPackage">
      <h5 class="quantity-title">Cantidad: {{product.qty}}</h5>
    </div>
    <div class="item-wrapper" v-if="!product.belongsToPackage">

      <h5 class="quantity-title">Cantidad</h5>

      <div class="item-quantity">
        <b-form-spinbutton
          v-model="product.qty"
          min="0"
          size="sm"
          class="ml-75"
          inline
          @change="changeQty($event, product)"
          :readonly="product.categoryName == 'Transfer'"
        />
      </div>

      <div class="item-cost mt-1" v-if="product.itinerario">
        <div class="item-cost mt-1" v-if="product.itinerario.extraPax > 0 ">
          <h5 class="item-price">Pax Extra: {{ product.itinerario.extraPax }}</h5>
        </div>
      </div>
    </div>
    <div class="mt-1">
      <div class="d-flex justify-content-between mb-2">
        <div class="">
        <b-button  variant="warning" :ref="'btnShowProduct' + product.uuid" @click="addSubProduct(product)" v-if="!product.belongsToPackage && product.modelType!='transfer'" >
          <feather-icon icon="CopyIcon" /> </b-button>
  
      </div>
      <div class="">
       
        <b-button variant="success" :ref="'btnShowProduct' + product.uuid" @click="openModalEditProduct(product)" >
          <feather-icon icon="EditIcon" />
        </b-button>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from "vuex";
import { stringAleatorio, globalCurrencyFormat } from '@/helpers/helpers'
import { market } from '@/modules/shop/mixins/market'

export default {
  mixins: [market],
  async mounted(){
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data(){
    return {
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      imdDefault: require("@/assets/images/default.jpg"),
      options: [
      { text: "No", value: 0 },
      { text: "Si", value: 1},
    ],
    }
  },
  computed:{
    ...mapState("shop", ["cart", "isReloadingProductsInCart"]),
  },
  methods:{
    ...mapActions('shop',['getContentForItineraries',"calculateTotalWithDiscounts"]),
    ...mapMutations("shop", ["calculateTotalNights", "addProductInCart",'changeQtyProductsPackage']),

    async changeQty(qty, item ){        
      if( qty == 0 ){
        this.deleteProductInCart(item)
      }
      if( qty > 0){
        await this.calculateTotalWithDiscounts()

        if( item.modelType === 'package'){
          this.changeQtyProductsPackage( { uuidPackage: item.uuid, qty } ) //si es paquete, cambiar qty de sus productos hijos agregados en el cart
        }
        await this.qtyHasChanged(item)    // desde el mixim market
      }
    },
    subTotalProductoQty(item) {
      const subTotal = item.displayrates.netSale?.toFixed(2) || 0.00
      return globalCurrencyFormat(subTotal)
    },

    deleteProductInCart( product ){
      this.manageRemoveProductInCart(product)
    },
    async openModalEditProduct(item) {
      const modal = "modal-edit-product" + item.uuid
      const btnShowProduct = "btnShowProduct" + item.uuid
      if (this.product.categoryName == 'Transfer') await this.getContentForItineraries()
      this.$root.$emit("bv::show::modal", modal, btnShowProduct)
    },
    async setPriceNights(item) {
      const total = item.saleprice / item.nights
      const nightsTotalPrice = item.nightsNum * total
      item.firstPay = (nightsTotalPrice).toFixed(2)
      await this.calculateTotalWithDiscounts(item)
    },
    async addSubProduct(product) {
      
      const item = structuredClone(product) // structuredClone hace un clonado profundo de lo que sea, evitando referenciar los datos de origen
      item.uuid = stringAleatorio() //les genero key unicos aleatorios
      item.isMainProduct = false // pasa a false para evitar conflictos
      // Paso a resetear los valores editables por el usuario
      item.clientnote = ''
      item.note = ''
      item.operationdate = null
      item.operationtime = '12:00'
      item.beneficiarios = { fullname: '', phone: '', email: '', room: '', }

      if (item.benefitDiscount || item.membershipDiscount) {
        item.discountApplied = 0
        item.benefitDiscount = false
        item.isBenefitDescout = false
        item.membershipDiscount = false
        item.typeDiscount = 1 // 'Sin descuento'
        item.promoName = ''
        item.promoId = null
        item.isPromo = false
      }

      if ( item.categoryName == 'Transfer' && item.modelType!="transfer" ) {
        const {paxcost, minPax, maxPax} = item.itinerario
        item.itinerario.nameBenefi = ''
        item.itinerario.lastNameBenefi = ''
        item.itinerario.trip = 'Arrival'
        item.itinerario.paxCost = paxcost
        item.itinerario.pax = 0
        item.itinerario.minPax =  minPax ? parseInt(minPax) : 0
        item.itinerario.extraPax = 0
        item.itinerario.amountExtraPax = '0.00'
        item.itinerario.maxPax = maxPax ? parseInt(maxPax) : 0
      }
      if ( item.modelType=="transfer" ) {
        const {operationDate} = item.itinerario
        item.operationdate = operationDate
      }
      item.orderdetailbeo = this.setBeo(item) // Importado desde mixin
      await this.manageAddProductInCart(item, item.categoryName) // se añade al carrito de nuevo
    }
  }
}
</script>
<style scoped src="@/assets/css/styles-checkout-products.css"></style>
<style scoped>
.subtotalproduct{
  text-decoration: underline #ff9f43 2px;
  font-weight: 600;
}
</style>